/* ============= Property Section1 ============= */
.proviewBanner {
  overflow: hidden;
  width: 100% !important;
  height: 90vh;
  position: relative;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../img/banner/propertyview.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.proview_BannerDesc h1 {
  color: white;
  font-size: 60px;
  font-family: var(---HeadFont);
}

.proview_BannerDesc p {
  color: white;
  font-family: var(---SubHeading);
}

/* ============= Property Section2 ============= */
.Property-Section2 {
  background-color: #eee;
  padding-top: 90px;
  padding-bottom: 50px;
}

.propertyList {
  text-align: left;
}
.propertyList p {
  margin: 0;
}
.propertyList h4 {
  text-align: left;
  display: flex;
  font-family: var(---HeadFont);
  margin: 0;
}

.propertyList h4 .property a {
  background: var(---PrimeColor);
  color: #fff;
  border: var(---PrimeColor) solid 1px;
  padding: 5px 10px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  border-radius: 20px;
  transition: 0.5s;
  text-decoration: none;
  margin: 5px 7px;
}

.propertyList h4 .property a:hover {
  background: #fff;
  color: var(---PrimeColor);
  border: var(---PrimeColor) solid 1px;
  padding: 5px 10px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 600;
  transition: 0.5s;
  text-decoration: none;
  font-family: var(---HeadFont);
}

.propertyList h5 {
  text-align: right;
  color: var(---PrimeColor);
  font-family: var(---HeadFont);
}

.propertyList h6 {
  text-align: right;
  font-family: var(---HeadFont);
}

.propertyGallery {
  background-color: #fff;
  padding: 20px;
}

.propertyGallery h4 {
  margin-bottom: 20px;
  text-align: left;
  color: var(---PrimeColor);
  font-family: var(---HeadFont);
}

.propertyImges {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 5px gray;
}

.propertyImges img {
  width: 100%;
  height: 100%;
  max-height: 500px;
}

.Property .swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 50px !important;
  height: 50px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---PrimeColor);
  border-radius: 50%;
  border: 7px solid white;
}

.Property .swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 50px !important;
  height: 50px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---PrimeColor);
  border-radius: 50%;
  border: 7px solid white;
}

.Property .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: #ffff !important;
}

.Property .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  color: #ffff !important;
  line-height: 1;
}

.buildingDetails h4 {
  margin-bottom: 20px;
  text-align: left;
  color: var(---PrimeColor);
  font-family: var(---HeadFont);
}

.buildingDetails h6 {
  margin-bottom: 20px;
  text-align: left;
  font-size: 16px;
}

.buildingDetails h6 span {
  margin-bottom: 20px;
  text-align: left;
  font-size: 14px;
}

.buildingDetails_amenities {
  background-color: #fff;
  padding: 20px;
}

.propertyDescription {
  padding: 20px;
  background-color: #fff;
}

.propertyDescription h4 {
  margin-bottom: 20px;
  text-align: left;
  color: var(---PrimeColor);
  font-family: var(---HeadFont);
}

.propertyDescription p {
  margin-bottom: 20px;
  text-align: left;
}

.proDetail_amenities {
  background-color: #fff;
  margin-top: 20px;
  padding: 20px;
}

.propertyDetails h4 {
  margin-bottom: 20px;
  text-align: left;
  color: var(---PrimeColor);
  font-family: var(---HeadFont);
}

.propertyDetails h6 {
  margin-bottom: 20px;
  text-align: left;
  font-size: 16px;
}

.propertyDetails h6 i svg {
  color: var(---PrimeColor);
  font-size: 16px;
}

.propertyDetails h6 span {
  margin-bottom: 20px;
  text-align: left;
  font-size: 14px;
}

.propertyAmenities {
  margin-top: 20px;
}

.propertyAmenities h4 {
  margin-bottom: 20px;
  text-align: left;
  color: var(---PrimeColor);
  font-family: var(---HeadFont);
}

.propertyAmenities p {
  text-align: left;
}

.propertyAmenities svg {
  color: var(---PrimeColor);
  font-weight: 700;
}

@media (max-width: 980px) {
  .proviewBanner {
    height: 40vh;
  }

  .proview_BannerDesc {
    padding: 0px 20px;
  }

  .proview_BannerDesc h1 {
    font-size: 30px;
    padding: 0px 10px;
  }

  .Property .swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 30px !important;
    height: 30px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---PrimeColor);
    border-radius: 50%;
    border: 3px solid white;
  }

  .Property .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 30px !important;
    height: 30px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---PrimeColor);
    border-radius: 50%;
    border: 3px solid white;
  }

  .Property .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: #ffff !important;
  }

  .Property .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    color: #ffff !important;
    line-height: 1;
  }
}

/* =============== Property Right Site ========= */

.property-view-main-con-form {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.propertyRight {
  background-color: #fff;
  padding: 20px;
}

.propertyRight h4 {
  text-align: left;
  color: var(---PrimeColor);
  font-family: var(---HeadFont);
}

.proAgent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ProAgent_img {
  width: 40%;
  height: 100%;
}

.ProAgent_img img {
  width: 100%;
  height: 100%;
  min-width: 100px;
  min-height: 100px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 50%;
}

.ProAgent_desc h5 {
  text-align: left;
  color: var(---PrimeColor);
  font-family: var(---HeadFont);
}

.ProAgent_desc p {
  text-align: left;
  font-family: var(---HeadFont);
}

.proAgentDetails_desc {
  text-align: left;
}

.proAgentDetails_desc p i {
  text-align: left;
  margin-right: 20px;
}

.proAgentDetails_desc p a {
  text-decoration: none;
  color: black;
}

.proAgentDetails_desc p i svg {
  color: var(---PrimeColor);
  font-size: 20px;
}

.Recentproperty {
  background-color: #fff;
  padding: 20px;
  margin-top: 50px;
}

.Recentproperty h4 {
  text-align: left;
  color: var(---PrimeColor);
  font-family: var(---HeadFont);
}

.propertyRecent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
}

.Recentproperty_img {
  width: 40%;
  height: 100%;
}

.Recentproperty_img img {
  width: 100%;
  height: 100%;
  min-width: 100px;
  min-height: 100px;
  max-width: 70px;
  max-height: 70px;
}

.Recentproperty_desc h5 {
  text-align: left;
  color: var(---PrimeColor);
  font-family: var(---HeadFont);
}

.Recentproperty_desc p {
  text-align: left;
  font-family: var(---HeadFont);
}

.Viewproperty {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
}

.ViewProperty .swiper-button-prev {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---PrimeColor);
  border-radius: 50%;
  border: 7px solid white;
  top: unset;
  bottom: 10px;
}

.ViewProperty .swiper-button-next {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---PrimeColor);
  border-radius: 50%;
  border: 7px solid white;
  top: unset;
  bottom: 10px;
  right: 10px;
}

.ViewProperty .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 12px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: #ffff !important;
}

.ViewProperty .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 12px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  color: #ffff !important;
  line-height: 1;
}

.viewPropertycard {
  overflow: hidden;
  width: 100% !important;
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  padding: 30px 0px;
  background-blend-mode: overlay;
}

.viewProperty_desc {
  padding: 20px;
  color: #fff;
}

.viewProperty_desc h3 {
  font-size: 20px;
  font-family: var(---HeadFont);
  text-align: left;
}

.viewProperty_desc p {
  font-family: var(---SubHeading);
  text-align: left;
  color: white;
}

.viewProperty_price h5 {
  font-size: 14px;
  font-family: var(---HeadFont);
  margin-top: 40px;
  margin-bottom: 0px;
  font-weight: 600;
  color: white;
}

.viewProperty_price h5 span {
  font-size: 14px;
  font-family: var(---HeadFont);
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 400;
}

/* =========== Property Section3 ========== */
.Property-Section3 {
  margin-bottom: 5 0px;
}

.propertyMap {
  margin-top: 50px;
  width: 100%;
  /* height: 100%; */
}

.propertyMap iframe {
  width: 100%;
  height: 100%;
  min-height: 350px;
  max-height: 300px;
}

@media (max-width: 430px) {
  .propertyList h4 {
    display: block;
  }

  .propertyList h4 .property a {
    margin: 10px 0px 0px 0px;
  }

  .propertyList {
    margin: 0px;
  }
}
