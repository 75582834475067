























/* /////////////////////////////////////////////////////////// */

.about-section-image-details {
  /* border: 1px solid red; */
}

.about-page-section-image-con {
  /* border: 1px solid blue; */
  height: 30vh;
}

.about-page-section-image-con img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about-section-page-about-details-con {
  height: 100%;
  display: flex;
  align-items: center;
  border-left: none;
}

.about-section-page-about-details {
  padding: 84px 80px 94px;
  border: 1px solid black;
  width: 100%;
  border-left: none;
}

.about-section-page-about-info {
  text-align: left;
}

.about-section-page-about-info h3 {
  font-weight: var(---fontWeight01);
  color: #1b1b1b;
  letter-spacing: .05em;
  font-family: var(---font01);
  text-transform: uppercase;
}

.about-section-page-about-info p {
  font-family: var(---font01);
  font-weight: 300;
}

.About-section-page-plus-btn {}


.home-section-svg-overlay-btn-con {
  display: inline-block;
}

.home-section-overlay-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  padding: 8px;
  border-radius: 50px;
  background-color: #363636;
}

.home-section-overlay-btn svg {
  font-size: 20px;
  color: white;
  transition: all 0.4s;
}

.team-member-contact-number{
  padding-left: 10px;
}

.home-section-overlay-btn:hover svg {
  transform: rotate(-90deg)
}



/* team section con  */
.our-team-row-start{
  justify-content: center;
}

.about-section-2-con {
  /* border: 1px solid blue; */
}

.about-section-2-card-con {
  margin: 0px 0px 30px 0px;
  /* border: 1px solid red; */
  overflow: hidden;
}

.about-section-2-card-body {
  /* border: 1px solid green; */
  height: 60vh;
  width: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
  -webkit-transform: scale(1.0001);
  -ms-transform: scale(1.0001);
  transform: scale(1.0001);
}

.about-section-2-card-body img {
  height: 100%;
  width: 100%;
  object-fit: cover;

}

/* card overlay  */

.about-section-2-card-details {
  text-align: left;
  padding: 40px 25px 20px;
  text-align: left;
  bottom: -1px;
  position: absolute;
  width: 100%;
  display: block;
  background-color: #fff;
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: bottom;
  transition: all .4s ease;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* transform: translate3d(0, 100%, 0); */
}

.about-section-2-card-details h3 {
  font-weight: bold;
  color: #1b1b1b;
  letter-spacing: .05em;
  font-family: var(---font01);
  text-transform: uppercase;
  font-size: 23px;
}

.about-section-2-card-details p {
  font-family: var(---font01);
  font-weight: bold;
}

.about-section-card-social-media-con {
  /* border: 1px solid blue; */
}

.about-section-card-social-media-con a {
  /* border: 1px solid red; */
  margin: 0px 5px;
}

.about-section-card-social-media-con a svg {
  font-size: 20px;
  color: #cfcfcf;
}

/* .about-section-2-card-con:hover .about-section-2-card-details {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
} */