/* ============= About Section1 ============= */
.aboutBanner {
  overflow: hidden;
  width: 100% !important;
  height: 30vh;
  position: relative;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../img/banner\ new/bannernew1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.about_BannerDesc {
  padding: 20px 0px 0px 0px;
}

.about_BannerDesc h1 {
  color: var(---PrimeColor);
  font-size: 40px;
  font-family: var(---HeadFont);
  text-align: left;
  /* padding: 0px 30px; */
  text-shadow: -1px -1px 0 #000,
    /* Top-left shadow */
    1px -1px 0 #000,
    /* Top-right shadow */
    -1px 1px 0 #000,
    /* Bottom-left shadow */
    2px 2px 0 #000;
  /* Bottom-right shadow; */
}

.about_BannerDesc p {
  color: white;
  font-size: 16px;
  font-family: var(---SubHeading);
  text-align: left;
}

/* =================== About Section2 =============== */
.aboutDesc h2 {
  font-size: 26px;
  font-family: var(---HeadFont);
  display: inline-block;
  position: relative;
  margin-bottom: 30px;
}

/* 
.aboutDesc h2::after {
  display: block;
  height: 2px;
  font-weight: bold;
  background-color: var(---PrimeColor);
  content: " ";
  width: 97px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  left: 0;
  right: 0;
} */

.aboutDesc h2::before {
  border-bottom: 2px solid var(---PrimeColor);
  content: "";
  font-family: var(---HeadFont);
  top: 65px;
  position: absolute;
  text-align: center;
  width: 100px;
  left: 50%;
  transform: translate(-50%, -25px);
}

/* =================== About Section2 =============== */
.aboutSection2 {
  background-color: #eee;
}

.missionCard {
  padding: 20px;
  box-shadow: 0 0 5px grey;
  border-radius: 5px;
  min-height: 250px;
  max-height: 170px;
}

.aboutMissionImg {
  width: 100%;
  height: 100%;
}

.aboutMissionImg img {
  width: 100%;
  height: 100%;
  max-width: 50px;
  margin-bottom: 20px;
}

.missionCard h4 {
  font-size: 26px;
  font-family: var(---HeadFont);
}

.missionCard p {
  font-size: 16px;
  font-family: var(---SubHeading);
}

.aboutValue {
  padding: 20px;
  border-radius: 5px;
}

.aboutValue_Desc i {
  font-size: 50px;
  color: var(---PrimeColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutValue_Desc svg {
  color: var(---PrimeColor);
}

.aboutValue h4 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.aboutValue p {
  font-family: var(---SubHeading);
  text-align: center;
  font-size: 16px;
  text-align: justify;
}

/* =================== About Section4 =============== */
.AboutCounter .headTitle h2 {
  color: #fff;
}

.AboutCounter .headTitle h2:before {
  border-bottom: 2px solid #fff;
}

.AboutCounter {
  overflow: hidden;
  width: 100% !important;
  height: 70vh;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../img/reviewbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-attachment: fixed;
}

.panelCounter {
  background-color: #fff;
  box-shadow: 0 5px 10px #808080;
  border-radius: 5px;
}

.panelCounter span svg {
  color: var(---PrimeColor);
}

.panelCounter ul {
  width: 100%;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.panelCounter ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px;
  font-family: var(---parafont);
}

.panelCounter ul li span:nth-child(1) {
  color: var(---primeColor);
  font-size: 3rem;
}

.panelCounter ul li span.countingClass span {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  font-family: var(---HeadFont);
}

.panelCounter ul li span:nth-child(3) {
  color: #808080;
  font-size: 16px;
  font-weight: 500;
  font-family: var(---SubHeading);
}

@media (max-width: 990px) {
  .aboutBanner {
    height: 40vh;
  }

  .about_BannerDesc {
    /* padding: 0px 20px; */
  }

  .about_BannerDesc h1 {
    font-size: 30px;
    padding: 0px 10px;
  }

  .AboutCounter {
    margin-top: 30px;
    height: auto;
  }

  .panelCounter ul li {
    padding: 20px 20px;
  }

  .missionCard {
    margin-bottom: 20px;
    min-height: 300px;
    max-height: 200px;
  }

  .panelCounter ul {
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .panelCounter ul {
    flex-wrap: wrap;
    padding: 10px 0;
    margin-bottom: 20px;
  }

  .panelCounter ul li {
    flex: 1 1 100px;
    padding: 0px 0px;
  }

  .panelCounter ul li span:nth-child(1) {
    font-size: 2.5rem;
  }

  .panelCounter ul li span.countingClass span {
    color: #111;
    font-size: 1.6rem;
    font-weight: 600;
  }

  .panelCounter ul li span:nth-child(3) {
    color: #808080;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
  }
}

@media(max-width:430px) {
  .about_BannerDesc h1 {
    font-size: 25px;
  }
}