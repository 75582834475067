/* ============= Property Section1 ============= */
.propertyBanner {
  overflow: hidden;
  width: 100% !important;
  height: 30vh;
  position: relative;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../img/newbanner/banner02.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.property_BannerDesc h1 {
  color: var(---PrimeColor);
  font-size: 40px;
  font-family: var(---HeadFont);
  text-align: left;
  /* padding: 0px 0px; */
  text-shadow: -1px -1px 0 #000,
    /* Top-left shadow */
    1px -1px 0 #000,
    /* Top-right shadow */
    -1px 1px 0 #000,
    /* Bottom-left shadow */
    2px 2px 0 #000;
  /* Bottom-right shadow; */
}

.property_BannerDesc p {
  color: white;
  font-size: 16px;
  font-family: var(---SubHeading);
  text-align: left;
}

/* ============= Property Section2 ============= */
.procard {
  overflow: hidden;
  width: 100% !important;
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  padding: 50px 0px;
  border-radius: 3px;
  max-height: 380px;
}

.pro_desc {
  padding: 20px;
  color: #fff;
}

.pro_desc h3 {
  font-size: 26px;
  font-family: var(---HeadFont);
  text-align: left;
  color: white;
}

.pro_desc p {
  font-family: var(---SubHeading);
  text-align: left;
  font-size: 16px;
  color: white;
}

.pro_price h5 {
  font-size: 16px;
  font-family: var(---HeadFont);
  margin-top: 40px;
  margin-bottom: 0px;
  color: white;
}

.pro_price h5 span {
  font-size: 14px;
  font-family: var(---HeadFont);
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 400;
  color: white;
}

.pro_btn {
  text-align: right;
}

.pro_btn a {
  background: var(---PrimeColor);
  color: #fff;
  border: var(---PrimeColor) solid 1px;
  padding: 5px 15px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: 0.5s;
  font-weight: 500;
  text-decoration: none;
}

.pro_btn a:hover {
  background: #fff;
  color: var(---PrimeColor);
  border: var(---PrimeColor) solid 1px;
  padding: 5px 15px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
}

.Property .swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 50px !important;
  height: 50px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---PrimeColor);
  border-radius: 50%;
  border: 7px solid white;
}

.Property .swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 50px !important;
  height: 50px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---PrimeColor);
  border-radius: 50%;
  border: 7px solid white;
}

.Property .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: #ffff !important;
}

.Property .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  color: #ffff !important;
  line-height: 1;
}

@media (max-width: 980px) {
  .propertyBanner {
    height: 40vh;
  }

  .property_BannerDesc h1 {
    font-size: 30px;
    padding: 0px 10px;
  }

  .Property .swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 30px !important;
    height: 30px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---PrimeColor);
    border-radius: 50%;
    border: 3px solid white;
  }

  .Property .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 30px !important;
    height: 30px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---PrimeColor);
    border-radius: 50%;
    border: 3px solid white;
  }

  .Property .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: #ffff !important;
  }

  .Property .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    color: #ffff !important;
    line-height: 1;
  }
}


@media(max-width:430px) {
  .property_BannerDesc h1 {
    font-size: 25px;
  }
}