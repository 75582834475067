/* ============= Services Section1 ============= */
.servicesBanner {
  overflow: hidden;
  width: 100% !important;
  height: 30vh;
  position: relative;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../img/newbanner/banner01.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  display: flex;
  justify-content: center;
  background-position: center;
  background-size: cover;
}

.services_BannerDesc h1 {
  color: var(---PrimeColor);
  font-size: 40px;
  font-family: var(---HeadFont);
  text-align: left;
  /* padding: 0px 0px; */
  text-shadow: -1px -1px 0 #000,
    /* Top-left shadow */
    1px -1px 0 #000,
    /* Top-right shadow */
    -1px 1px 0 #000,
    /* Bottom-left shadow */
    2px 2px 0 #000;
  /* Bottom-right shadow; */
}

.services_BannerDesc p {
  color: white;
  font-family: var(---SubHeading);
  font-size: 16px;
  text-align: left;
}

/* ========== Services Section2 ========= */

.servicePageCard {
  padding-top: 10px;
  border-radius: 5px;
  /* min-height: 280px;
  max-height: 180px; */
}

.servicePageImg {
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;
}

.servicePageImg img {
  width: 100%;
  height: 100%;
}

.servicePageCard h4 {
  font-size: 1.6rem;
  font-family: var(---HeadFont);
  font-weight: 600;
  color: var(---SecondColor);
}

.servicePageCard p {
  font-size: .95rem;
  font-family: var(---ParaFont);
}

.paddingSection {
  padding: 20px 0px 0px 0px;
}



@media (max-width: 980px) {
  .servicesBanner {
    height: 50vh;
  }

  .services_BannerDesc {
    /* padding: 0px 20px; */
  }

  .services_BannerDesc h1 {
    font-size: 30px;
    padding: 0px 10px;
  }

  .servicePageCard {
    margin-bottom: 20px;
  }

  .paddingSection {
    padding: 20px 0;
  }
}

.Service_MissionVision {
  background: #eee;
}


.Service_missionCard {
  padding: 20px;
  box-shadow: 0 0 5px grey;
  border-radius: 5px;
  min-height: 250px;
  max-height: 170px;
  background-color: #fff;
}

.Service_MissionImg {
  width: 100%;
  height: 100%;
}

.Service_MissionImg img {
  width: 100%;
  height: 100%;
  max-width: 50px;
  margin-bottom: 20px;
}

.Service_missionCard h4 {
  font-size: 26px;
  font-family: var(---HeadFont);
}

.Service_missionCard p {
  font-size: 16px;
  font-family: var(---SubHeading);
}

.property-serach label {
  color: black;
}


@media (max-width: 768px) {
  .servicesBanner {
    height: 40vh;
  }
}

@media(max-width:430px) {
  .services_BannerDesc h1 {
    color: var(---PrimeColor);
    font-size: 25px;
  }
}