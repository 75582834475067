.home-facebook-btn-con {
    /* border: 2px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-facebook-btn {
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 20px;
    border-radius: 10px;
    background-color: var(---SecondColor);
}

.home-facebook-btn-con a{
    text-decoration: none;
}

.home-facebook-btn-con a:hover{
    cursor: pointer;
}

.home-facebook-btn h3 {
    color: var(---PrimeColor);
    font-family: var(---HeadFont);
    font-size: 20px;
    margin: 0px 10px 0px 0px;
}

.home-facebook-btn span{
    border: 2px solid var(---PrimeColor);
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.home-facebook-btn svg {
    color: var(---PrimeColor);
    font-size: 20px;
}