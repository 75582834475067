/* ============ Common Css ============= */
.headTitle {
  padding-bottom: 30px;
  position: relative;
}

.video-container {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: -1; */
}

.back-video {
  /* min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto; */
}

.headTitle h2 {
  text-transform: uppercase;
  font-family: var(---HeadFont);
  color: var(---PrimeColor);
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  padding-bottom: 20px;
}

.headTitle h2 span {
  text-transform: uppercase;
  font-family: var(---HeadFont);
  color: var(---SecondColor);
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  padding-bottom: 20px;
}

.headTitle h2:before {
  border-bottom: 2px solid var(---PrimeColor);
  content: "";
  font-family: var(---HeadFont);
  top: 65px;
  position: absolute;
  text-align: center;
  width: 100px;
  left: 50%;
  transform: translate(-50%, -25px);
}

.headTitle p {
  font-family: var(---ParaFont);
  margin-top: 20px;
  text-align: center;
  padding: 0px 80px;
}

.swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 50px !important;
  height: 50px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---PrimeColor);
  border-radius: 50%;
  border: 7px solid white;
}

.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 50px !important;
  height: 50px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---PrimeColor);
  border-radius: 50%;
  border: 7px solid white;
}

.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: #ffff !important;
}

.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  color: #ffff !important;
  line-height: 1;
}

@media (max-width: 980px) {
  .swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 30px !important;
    height: 30px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---PrimeColor);
    border-radius: 50%;
    border: 3px solid white;
  }

  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 30px !important;
    height: 30px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---PrimeColor);
    border-radius: 50%;
    border: 3px solid white;
  }

  .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: #ffff !important;
  }

  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    color: #ffff !important;
    line-height: 1;
  }
}

@media (max-width: 980px) {
  .headTitle h2 {
    font-size: 26px;
  }

  .headTitle p {
    padding: 0px 0px;
  }
}

/* =========== Home Banner Vidoes ======= */
.homeVidoes {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0);
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.back-video {
  width: 100%;
  height: 100%;
  opacity: 0.85;
}

/* .banner-title {
  position: absolute;
} */

/* .banner-title h1 {
  color: var(---PrimeColor);
  font-size: 40px;
  font-family: var(---HeadFont);
  text-align: left;
  text-shadow: -1px -1px 0 #000,
    Top-left shadow
    1px -1px 0 #000,
    Top-right shadow
    -1px 1px 0 #000,
    Bottom-left shadow
    3px 3px 0 #000;
  Bottom-right shadow;
} */



.banner-title {
  padding-top: 6vh;
}

.banner-title-main-con {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-title-main-con h1 {
  /* border: 1px solid red; */
  width: 80%;
  font-family: var(---ParaFont);
  font-weight: 600;
  background: var(---SecondColor);
  color: var(---PrimeColor);
  padding: 15px 40px;
  font-size: 30px;
  border-radius: 10px;
  box-shadow: 0 0 5px gray;
  text-align: center;
}


.banner_btn {
  display: flex;
  justify-content: space-around;
}

.banner_btn a {
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  transition: 0.5s;
  text-decoration: none;
  margin: 30px 0px;
}

.banner_btn a:hover {
  background: #fff;
  color: #000;
  border: 1px solid #fff;
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: 0.5s;
  text-decoration: none;
  margin-top: 30px;
  transition: 0.3s ease-in-out;
}

/* ========== Home Property ====== */
/* .home_property {
  background-color: #eee;
} */
.ResidentalProperty a {
  text-decoration: none;
}

.CommercialProperty {
  background-color: #eee;
}

.CommercialProperty a {
  text-decoration: none;
}

.package-item {
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 30px;
}

.package-item img {
  transition: 0.5s;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 300px;
}

.package-item:hover img {
  transform: scale(1.1);
}

.home_proImg {
  position: relative;
}

.home_proTxt1 {
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 15px;
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.home_proTxt1 .home_probtn1 {
  text-decoration: none;
  color: #fff;
  background-color: #4e4d8e;
  padding: 4px 7px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
}

.home_proTxt1 .home_probtn2 {
  text-decoration: none;
  color: #fff;
  background-color: var(---outright);
  padding: 4px 7px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.home_probtn2.outright {
  background-color: red;
}

.home_probtn2.lease {
  background-color: #FF7900;
}

.home_proTxt2 {
  color: #000;
  position: absolute;
  bottom: 0px;
  left: 0;
  margin: 10px 15px;
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.home_proTxt2 .home_probtn1 {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

.home_proTxt2 .home_probtn2 {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

.home_proTxt2 .home_probtn3 {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

.home_prodesc {
  padding: 20px;
}

.home_prodesc h2 {
  text-align: left;
  font-size: 20px;
  text-transform: capitalize;
  color: var(---SecondColor);
}

.home_prodesc p {
  text-align: left;
  font-size: 14px;
  font-weight: 300;
}

.home_prodesc h2:hover {
  color: var(---PrimeColor);
}

.home_prolist h6 {
  color: var(---PrimeColor);
  font-size: 16px;
}

.home_prolist i svg {
  color: var(---PrimeColor);
  font-size: 20px;
}

.home_prolist span {
  color: var(---PrimeColor);
  font-size: 16px;
}

.home_proprice h5 {
  font-size: 16px;
  font-family: var(---HeadFont);
  margin-bottom: 0px;
  color: var(---PrimeColor);
}

.home_proprice h5 span {
  font-size: 14px;
  font-family: var(---HeadFont);
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 400;
  color: var(---PrimeColor);
}

.homeProperty_btn {
  text-align: center;
  margin-top: 20px;
}

.homeProperty_btn a {
  color: var(---PrimeColor);
  border: 2px solid var(---PrimeColor);
  padding: 10px 35px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  transition: 0.5s;
  text-decoration: none;
  margin: 30px 0px;
}

.homeProperty_btn a:hover {
  background: var(---SecondColor);
  border: 3px solid var(---PrimeColor);
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  transition: 0.5s;
  margin-top: 30px;
  transition: 0.3s ease-in-out;
}

/* ============= Secstion 1 ========= */
#hero {
  margin-top: -55px;
  background-image: url("../img/banner/homebanner.jpg");
  height: 100vh;
  background-size: 100% 100%;
}

.appointment-sec .slider_form {
  position: relative;
  padding: 25px 25px;
  background: #fff;
  border-radius: 10px;
  bottom: 95px;
  box-shadow: 0px 5px 20px 0px rgb(0 0 0 / 7%);
  margin: 0 auto;
}

.appointment-sec {
  background: #e8f8f8;
}

.homeservImg {
  width: 100%;
  height: 100%;
  display: flex;
}

.homeservImg img {
  max-width: 50px;
  max-height: 50px;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.homeCard h4 {
  font-size: 26px;
  font-family: var(---HeadFont);
  color: var(---PrimeColor);
  text-align: left;
}

.homeCard p {
  font-size: 16px;
  font-family: var(---ParaFont);
  text-align: left;
}

/* ============= HomeSection2 =============== */
.homeSection2 {
  background-color: #eee;
}

.aboutImg-con {
  height: 90%;
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.aboutImg {
  height: auto;
  width: 100%;
  position: relative;
  padding: 0 30px 30px 15px;
}

.aboutImg img {
  height: 100%;
  width: 100%;
  z-index: 1000;
  transition: all 0.5s ease-in-out;
}

.aboutImg::after {
  content: "";
  width: 50%;
  position: absolute;
  top: -6%;
  bottom: 60%;
  left: -3%;
  background-image: url("../img/dot.jpg");
  background-repeat: repeat;
  z-index: -1;
}

.aboutImg::before {
  content: "";
  position: absolute;
  top: 50px;
  right: 0;
  left: 45px;
  bottom: 0;
  background: linear-gradient(90deg,
      rgba(66, 65, 135, 0.7),
      rgba(66, 65, 135, 0.7)),
    url("../img/banner/aboutbanner.jpeg");
  z-index: -1;
}

.aboutList {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutDesc {
  text-align: left;
}

.aboutDesc h2 {
  font-family: var(---HeadFont);
  font-size: 28px;
  color: var(---PrimeColor);
}

.aboutDesc h2 span {
  font-family: var(---HeadFont);
  font-size: 28px;
  color: var(---SecondColor);
}

.aboutDesc p {
  font-family: var(---ParaFont);
  font-size: 16px;
}

.read-or-hide {
  color: blue;
  margin-left: 10px;
}

/* ================= Home Section3 ================ */
.homeSection3 {
  background-color: #eee;
  padding: 50px 0px;
}

.propertycard {
  overflow: hidden;
  width: 100% !important;
  position: relative;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://media.istockphoto.com/id/615398376/photo/new-york-city-nyc-usa.webp?b=1&s=612x612&w=0&k=20&c=smfvu75jaLFnRZwicmOCRqMiS1KecqFUh_KTPQg9ctI=");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  padding: 50px 0px;
  max-height: 380px;
}

.property_desc {
  padding: 20px;
  color: #fff;
}

.property_desc h3 {
  font-size: 26px;
  font-family: var(---HeadFont);
  text-align: left;
}

.property_desc p {
  font-family: var(---ParaFont);
  text-align: left;
  font-size: 16px;
  color: white;
}

.property_price h5 {
  font-size: 16px;
  font-family: var(---HeadFont);
  margin-top: 40px;
  margin-bottom: 0px;
}

.property_price h5 span {
  font-size: 14px;
  font-family: var(---HeadFont);
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 400;
}

.property_btn {
  text-align: right;
}

.property_btn a {
  background: var(---PrimeColor);
  color: #fff;
  border: var(---PrimeColor) solid 1px;
  padding: 5px 15px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: 0.5s;
  font-weight: 500;
  text-decoration: none;
  margin-top: 30px;
}

.property_btn a:hover {
  background: #fff;
  color: var(---PrimeColor);
  border: var(---PrimeColor) solid 1px;
  padding: 5px 15px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
  margin-top: 30px;
}

/* ============== Home Section4 ============== */
.home-help {
  overflow: hidden;
  width: 100% !important;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("https://code-theme.com/html/findhouses/images/bg/bg-info3.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
}

.help_btn a {
  color: var(---PrimeColor);
  border: 1px solid var(---PrimeColor);
  padding: 10px 20px;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  transition: 0.5s;
  text-decoration: none;
  background-color: #fff;
}

.help_btn a:hover {
  background: var(---PrimeColor);
  color: #000;
  padding: 10px 20px;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: 0.5s;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.info-text {
  padding: 20px;
}

.info-text h3 {
  color: #fff;
  text-align: left;
  padding-bottom: 20px;
  font-size: 28px;
  font-family: var(---HeadFont);
  color: var(---PrimeColor);
  font-weight: 700;
}

.info-text p {
  color: #fff;
  text-align: left;
  font-size: 16px;
  font-family: var(---ParaFont);
}

/* ============== Home Brands ============== */
.homeBrands {
  background: #eee;
}

.brand_img {
  width: 100%;
  height: 26vh;
  padding: 20px;
  margin-bottom: 30px;
  /* box-shadow:0 0 7px grey; */
}

.brand_img img {
  width: 100%;
  height: 26vh;
  /* min-width: 240px;
  min-height: 140px;
  max-width: 200px;
  max-height: 100px; */
  box-shadow: 0 0 7px grey;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
}

/* ============ homeSection6 ============== */
.homeSection6 {
  background-color: #eee;
}

.testimonial {
  text-align: left;
  padding: 10px;
  background-color: white;
}

.testimonal_img {
  width: 100%;
  height: 100%;
}

.testimonal_img img {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 5px grey;
}

.testimonal_desc {
  background-color: white;
}

.testimonal_desc h3 {
  font-family: var(---HeadFont);
  font-size: 26px;
  color: var(---PrimeColor);
  text-transform: capitalize;
}

.testimonal_desc p {
  font-family: var(---headingfont);
  font-size: 16px;
}

/* ============ Home Contact Section ============== */
.Enquiry_btn {
  text-align: left;
}

.Enquiry_btn a {
  background: var(---PrimeColor);
  color: #fff;
  border: var(---PrimeColor) solid 1px;
  padding: 5px 15px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: 0.5s;
  text-decoration: none;
  margin: 30px 0px;
}

.Enquiry_btn a:hover {
  background: #fff;
  color: var(---PrimeColor);
  border: var(---PrimeColor) solid 1px;
  padding: 5px 15px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 600;
  transition: 0.5s;
  text-decoration: none;
  margin-top: 30px;
  transition: 0.3s ease-in-out;
}

.homeContact {
  overflow: hidden;
  width: 100% !important;
  position: relative;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../img/contactimg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  height: 70vh;
}

.homeContact_list {
  color: #fff;
  text-align: left;
  padding: 20px;
}

.homeContact_list p {
  color: #fff;
  font-size: 16px;
  font-family: var(---ParaFont);
}

.homeContact_desc p {
  color: #fff;
  font-size: 16px;
  font-family: var(---ParaFont);
}

.homeContact_desc p i {
  color: #fff;
  font-size: 16px;
}

.homeContact_desc p svg {
  color: var(---PrimeColor);
  margin-right: 10px;
  font-size: 22px;
}

.homeContact_desc p a {
  color: #fff;
  text-decoration: none;
}

.contactForm button {
  color: var(---PrimeColor);
  border: 1px solid var(---PrimeColor);
  /* padding: 10px 20px; */
  display: inline-block;
  cursor: pointer;
  /* font-size: 16px; */
  transition: 0.5s;
  text-decoration: none;
  background-color: #fff;
}

.contactForm button:hover {
  background: var(---PrimeColor);
  color: #000;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: 0.5s;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  display: flex !important;
  color: var(---PrimeColor) !important;
  border: 1px solid var(---PrimeColor) !important;
}

.homeCont .form-control {
  display: block;
  width: 100%;
  padding: 15px 10px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(---PrimeColor);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: initial;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* ======== Home Portfolio ======= */

.Home_Portfolio {
  background-color: #eee;
}

.homePort_img1 {
  width: 100%;
  height: 460px;
  background: linear-gradient(90deg,
      rgba(207, 206, 247, 0.2),
      rgba(207, 206, 247, 0.2)),
    url("https://chicagowpresidence.b-cdn.net/wp-content/uploads/2022/06/city11.jpg");
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 30px;
  object-fit: cover;
  background-size: 100% 100%;
}

.homePort_img2 {
  width: 100%;
  height: 220px;
  background: linear-gradient(90deg,
      rgba(207, 206, 247, 0.2),
      rgba(207, 206, 247, 0.2)),
    url("https://chicagowpresidence.b-cdn.net/wp-content/uploads/2022/06/city12.jpg");
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 30px;
  object-fit: cover;
  background-size: 100% 100%;
}

.homePort_img3 {
  width: 100%;
  height: 220px;
  background: linear-gradient(90deg,
      rgba(207, 206, 247, 0.2),
      rgba(207, 206, 247, 0.2)),
    url("https://chicagowpresidence.b-cdn.net/wp-content/uploads/2022/06/city10.jpg");
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 30px;
  object-fit: cover;
  background-size: 100% 100%;
}

.homePort_img4 {
  width: 100%;
  height: 460px;
  background: linear-gradient(90deg,
      rgba(207, 206, 247, 0.2),
      rgba(207, 206, 247, 0.2)),
    url("https://chicagowpresidence.b-cdn.net/wp-content/uploads/2022/06/city7.jpg");
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 30px;
  object-fit: cover;
  background-size: 100% 100%;
}

.homePort_txt {
  color: #fff;
  text-align: center;
}

.homePort_txt h4 {
  color: #fff;
  text-align: center;
}

.homePort_txt a {
  color: #fff;
  background-color: var(---PrimeColor);
  text-decoration: none;
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 5px;
}

/* ======== Home Categories ======= */

.homeCateg_img {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 30px;
  object-fit: cover;
  background-size: 100% 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.homeCateg_txt {
  color: #fff;
  text-align: center;
}

.homeCateg_txt h4 {
  color: #fff;
  text-align: center;
}

.homeCateg_txt a {
  color: #fff;
  background-color: var(---PrimeColor);
  text-decoration: none;
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 5px;
}

.homeCateg_img:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

/* ========= Home Service ========= */
.home_ServCard {
  box-shadow: 0 0 7px grey;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 260px;
  max-height: 160px;
}

.home_ServCard:hover {
  background-color: #eee;
  transition: all 0.9s ease-in-out;
}

.home_ServImg {
  width: 100%;
  height: 100%;
  display: flex;
}

.home_ServImg img {
  max-width: 50px;
  max-height: 50px;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.home_ServCard h4 {
  font-size: 26px;
  font-family: var(---HeadFont);
  /* color: var(---PrimeColor); */
  text-align: left;
}

.home_ServCard p {
  font-size: 16px;
  font-family: var(---ParaFont);
  text-align: left;
}

/* ========= Home Insta Story =========== */
/* .homeInsta {
  background: #eee;
} */

.homeInstr_img {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  object-fit: cover;
  background-size: 100% 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.homeInstr_txt {
  color: #fff;
  text-align: center;
}

.homeInstr_txt i {
  color: #fff;
  text-align: center;
  font-size: 80px;
}

/* ========= Home Property List =========== */
/* .homePro_list {
  background-color: #eee;
} */
.propertyList_img {
  width: 100%;
  height: 100px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  background-size: 100% 100%;
  border: 2px solid var(---PrimeColor);
}

/* ========== Home Why Choose Us ===== */
.home_choose {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("https://sdgrealty.in/wp-content/uploads/2023/01/slider2-scaled.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.choose_section i {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.choose_section i svg {
  font-size: 50px;
  color: var(---PrimeColor);
}

.choose_section h4 {
  font-size: 20px;
  text-align: center;
  color: var(---PrimeColor);
}

.choose_section p {
  text-align: center;
  color: #fff;
  font-size: 16px;
}

@media (max-width: 980px) {
  /* .banner-title h1 {
    font-size: 25px;
    padding: 0px 20px;
  } */

  .banner_btn a {
    padding: 5px 10px;
  }

  .homeContact {
    height: auto;
  }

  .homeContact_list {
    padding: 20px 0px;
  }

  .package-item {
    margin-bottom: 20px;
  }

  .aboutImg-con {
    margin-top: 0px;
  }

  .aboutImg {
    width: 70vh;
    position: relative;
    padding: 0 30px 30px 15px;
  }

  .aboutImg img {
    object-fit: contain;
  }

  .aboutImg-con {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
  }

  .aboutDesc {
    padding: 0px;
  }

  .home_ServCard {
    min-height: 350px;
    max-height: 250px;
  }

  .homePort_img1 {
    height: 250px;
    padding: 0px 30px;
    margin-bottom: 20px;
  }

  .homePort_img2 {
    margin-bottom: 20px;
  }

  .homePort_img3 {
    margin-bottom: 20px;
  }

  .homePort_img4 {
    margin-bottom: 20px;
  }

  .homeCateg_img {
    margin-bottom: 20px;
  }

  .contactForm button {
    margin-bottom: 20px;
  }

  .homeInstr_img {
    margin-bottom: 20px;
  }

  .propertyList_img {
    margin-bottom: 20px;
  }

  .choose_section {
    margin-bottom: 30px;
  }

  .choose_section i svg {
    font-size: 50px;
    color: var(---PrimeColor);
  }
}

@media (max-width: 790px) {
  .home_ServCard {
    min-height: auto;
    max-height: 100%;
  }
}

@media (max-width: 500px) {
  .brand_img {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* ======= Social MEdia Link ===== */
.socialMedia {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1000;
}

.socialMedia ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.socialMedia ul li a {
  align-items: center;
  border-radius: 50px;
  display: flex;
  height: 50px;
  justify-content: center;
  margin: 0 7px;
  width: 50px;
}

.socialMedia ul li i {
  align-items: center;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 40px;
  height: 100%;
  justify-content: center;
  transition: 0.5s ease-in-out;
  width: 100%;
}

.socialMedia .facebook {
  background-color: #3b5998;
}

.socialMedia .whatsapp {
  background-color: #47c757;
}

.socialMedia .instagram {
  background: linear-gradient(45deg,
      #f09433,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888);
}

.socialMedia .youtube-play {
  background-color: #f30c0c;
}

.socialMedia ul li i:hover {
  transform: scale(1.4);
  background: none;
}

.socialMedia .facebook:hover {
  color: #3b5998;
}

.socialMedia .youtube-play:hover {
  color: red;
}

.socialMedia .twitter:hover {
  color: #4d8fad;
}

.socialMedia .whatsapp:hover {
  color: #47c757;
}

.socialMedia .instagram:hover {
  color: #d92e7f;
}

/* =========== Home Form Section ======== */
/* .homeCont{
    background-color: #eee;
  } */

/* ============= Freatured Listng =========== */
.freaturedListing {
  margin-top: 30px;
  background: url("../img/banner/bg.jpg");
}

.freaturedListing .row {
  margin: 0px;
  padding: 0px;
}

.freaturedListing .row .col-lg-4 {
  margin: 0px;
  padding: 1px;
}

.freaturedDesc h2 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  text-align: center;
}

.freaturedImg {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 300px;
  position: relative;
  z-index: 2;
  display: block;
  margin: 0 auto;
}

.freaturedImg .fl-img {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
  transform-origin: center;
  transition: all .8s ease-in-out !important;
  background-color: rgb(0, 0, 0, .3);
    background-blend-mode: overlay;
}

.freaturedImg .fl-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  overflow: hidden;
  outline: none !important;
  display: block;
}

.freaturedImg .fl-item .fl-img {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
  transform-origin: center;
  transition: all .8s ease-in-out !important;
  background-color: rgb(0, 0, 0, 0.2);
  background-blend-mode: overlay;
}

.freaturedImg .fl-item:hover .fl-img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
  transition: all .5s ease-in-out !important;
}

/* .freaturedImg .fl-item .flitemcolored {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
} */

.freatured_info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  transition: all .8s ease-in-out !important;
}

.freatured_info .col-4 {
  margin: 0px;
  padding: 0px;
}

.freatured_info .freatured-price h5 {
  font-size: 20px;
  color: #fff;
  align-items: flex-start;
  margin-left: 10px;
}

.freatured_info .col-6 {
  margin: 0px;
  padding: 0px;
}

.freatured_info .freatured-address h5 {
  font-size: 20px;
  color: #fff;
  text-align: right;
}

.freaturedImg:hover .freatured_info {
  display: none;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.freatured_info1 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  background-color: white;
  transition: all 0.4s ease-in-out !important;
}

.freatured_info1 .col-8 {
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.freatured_info1 .freaturedPro_desc h5 {
  font-size: 20px;
}

.freatured_info1 .col-4 {
  margin: 0px;
  padding: 0px;
  background-color: #3b5998;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.freatured_info1 .freaturedPro_price a {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.freaturedImg:hover .freatured_info1 {
  display: block;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.freaturedDescBtn {
  margin-top: 40px;
}

.freatured_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.freatured_btn a {
  color: var(---SecondColor);
  border: 2px solid var(---SecondColor);
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  transition: 0.5s;
  text-decoration: none;
  width: 80%;
  margin: 10px;
}

.freatured_btn:hover a {
  color: #fff;
  background-color: var(---SecondColor);
  border: 3px solid var(---PrimeColor);
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  transition: 0.5s;
  text-decoration: none;
  width: 80%;
  margin: 10px;
  font-weight: 700;
}

@media (max-width: 992px) {
  .freatured_info .freatured-price h5 {
    font-size: 16px;
    color: #fff;
    align-items: flex-start;
    margin-left: 10px;
  }

  .freatured_info .freatured-address h5 {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .freatured_info .freatured-price {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0px 10px 0px 30px;
  }

  .freatured_info1 .freaturedPro_desc h5 {
    font-size: 18px;
  }

  .freatured_info .freatured-address {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0px 10px 0px 30px;
  }
}

/* ============= Freatured Commercial Listng =========== */
.freatureCommercial_Listing .row {
  margin: 0px;
  padding: 0px;
}

.freatureCommercial_Listing .row .col-lg-4 {
  margin: 0px;
  padding: 1px;
}

.freaturedDesc h2 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  text-align: center;
}

.freaturedImg {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 300px;
  position: relative;
  z-index: 2;
  display: block;
  margin: 0 auto;
  transition: all .8s ease-in-out !important;
}

.freaturedImg .fl-img {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
  transform-origin: center;
  transition: all .8s ease-in-out !important;
}

.freaturedImg .fl-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  overflow: hidden;
  outline: none !important;
  display: block;
}

.freaturedImg .fl-item .fl-img {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
  transform-origin: center;
  transition: all .8s ease-in-out !important;
}

.freaturedImg .fl-item:hover .fl-img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
}

/* .freaturedImg .fl-item .flitemcolored {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
} */

.freatured_info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  transition: all .8s ease-in-out !important;
}

.freatured_info .col-4 {
  margin: 0px;
  padding: 0px;
}

.freatured_info .freatured-price h5 {
  font-size: 20px;
  color: #fff;
  align-items: flex-start;
  margin-left: 10px;
  font-family: var(---HeadFont);
}

.freatured_info .col-6 {
  margin: 0px;
  padding: 0px;
}

.freatured_info .freatured-address h5 {
  font-size: 20px;
  color: #fff;
  text-align: right;
  font-family: var(---HeadFont);
}

.freaturedImg:hover .freatured_info {
  display: none;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.freatured_info1 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  background-color: white;
  transition: all .8s ease-in-out !important;
}

.freatured_info1 .col-8 {
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 50px;
}

.freaturedPro_desc {
  display: flex;
}

.freatured_info1 .freaturedPro_desc h5 {
  font-size: 16px;
  font-family: var(---HeadFont);
  padding: 0px 10px 0px 10px;
  border-right: 1px solid black;
}

.freatured_info1 .freaturedPro_desc h4 {
  font-size: 16px;
  font-family: var(---HeadFont);
  padding: 0px 0px 0px 10px;
}

.freatured_info1 .col-4 {
  margin: 0px;
  padding: 0px;
  background-color: #3b5998;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.freatured_info1 .freaturedPro_price a {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.freaturedImg:hover .freatured_info1 {
  display: block;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.freaturedDescBtn {
  margin-top: 40px;
}

.freatured_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.freatured_btn a {
  color: var(---SecondColor);
  border: 2px solid var(---SecondColor);
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  transition: 0.5s;
  text-decoration: none;
  width: 80%;
  margin: 10px;
}

.freatured_btn:hover a {
  color: #fff;
  background-color: var(---SecondColor);
  border: 3px solid var(---PrimeColor);
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  transition: 0.5s;
  text-decoration: none;
  width: 80%;
  margin: 10px;
  font-weight: 700;
}


.property-section-2-not-found-image-con {
  display: flex;
  justify-content: center;
  align-items: center;
}

.property-section-2-not-found-image {
  max-width: 70%;
  height: 100%;
}

.property-section-2-not-found-image h3 {
  text-align: center;
  margin: 16px 0px;
  color: var(---PrimeColor);
}

.property-section-2-not-found-image img {
  height: 100%;
  width: 100%;
}

/* Custom CSS */
.ant-input::placeholder {
  color: black;
  /* Change the color to black */
}

/* Custom CSS for Input */
.ant-input::placeholder {
  color: black;
  /* Change the color to black */
}

/* Custom CSS for Select */
.property-select-color::placeholder {
  color: black !important;
  /* Change the color to black */
}




@media (max-width: 992px) {
  .freatured_info .freatured-price h5 {
    font-size: 16px;
    color: #fff;
    align-items: flex-start;
    margin-left: 10px;
  }

  .freatured_info .freatured-address h5 {
    font-size: 16px;
  }
  .homeVidoes {
    height: 50vh;
  }
}

@media(max-width:768px){
  .banner-title-main-con h1 {
    width: 100%;
    font-size: 25px;
    text-align: center;
    padding: 15px 15px;
  }
  .homeVidoes {
    height: 40vh;
  }
  
}

@media (max-width: 500px) {
  .freatured_info .freatured-price {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0px 10px 0px 30px;
  }

  .freatured_info1 .freaturedPro_desc h5 {
    font-size: 18px;
  }

  .freatured_info .freatured-address {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0px 10px 0px 30px;
  }

}

@media(max-width:430px) {
  .headTitle h2 {
    padding: 0px;
  }

  .headTitle h2:before {
    top: unset;
    bottom: 0px;
  }

  .banner-title h1 {
    font-size: 17px;
  }

  .homeVidoes {
    height: 31vh;
  }
}