.footer {
  background-color: #eee;
  padding:  40px 0px 20px 0px;
}
.footerDesc {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
/* .footer-logo {
  height: 30vh;
  width: 70%;
  margin-bottom: 10px;
} */

.footer-logo {
  width: 200px;
  margin-bottom: 10px;
}

.footer-logo img {
  height: 100%;
  width: 100%;
}
.footerDesc p {
  margin-bottom: 15px;
  font-family: var(---ParaFont);
  text-align: justify;
  color: #111;
}

@media (max-width: 990px) {
  .footerDesc {
    margin-right: 0px;
  }
}
/* =============== Footer Useful Links ============ */
.footerUseful_link h4 {
  text-align: left;
  font-family: var(---HeadFont);
  font-size: 20px;
  font-weight: 600;
  color: var(---SecondColor);
}
.footerUseful_link ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  line-height: 26px;
}
.footerUseful_link ul li {
  text-align: left;
}
.footerUseful_link ul li svg {
  color: var(---PrimeColor);
  margin-right: 7px;
}
.footerUseful_link ul li:hover svg {
    color: #3a3981;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    margin-left: 10px;
  }
.footerUseful_link ul li a {
  text-decoration: none;
  text-align: justify;
  font-family: var(---ParaFont);
  color: #111;
  font-size: 16px;
}

/* =============== Footer Property Types ============ */
.footerProperty_Types h4 {
  text-align: left;
  font-family: var(---HeadFont);
  font-size: 20px;
  font-weight: 600;
  color: var(---SecondColor);
}
.footerProperty_Types ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  line-height: 26px;
}
.footerProperty_Types ul li {
  text-align: left;
}
.footerProperty_Types ul li svg {
  color: var(---PrimeColor);
  margin-right: 7px;
}
.footerProperty_Types ul li:hover svg {
    color: #3a3981;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    margin-left: 10px;
  }
.footerProperty_Types ul li a {
  text-decoration: none;
  text-align: justify;
  font-family: var(---ParaFont);
  color: #111;
  font-size: 16px;
}

/* =============== Footer Properties Available  ============ */
.footerProperties_Available h4 {
  text-align: left;
  font-family: var(---HeadFont);
  font-size: 20px;
  font-weight: 600;
  color: var(---SecondColor);
}
.footerProperties_Available ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  line-height: 26px;
}
.footerProperties_Available ul li {
  text-align: left;
}
.footerProperties_Available ul li svg {
  color: var(---PrimeColor);
  margin-right: 7px;
}
.footerProperties_Available ul li:hover svg {
  color: #3a3981;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin-left: 10px;
}
.footerProperties_Available ul li a {
  text-decoration: none;
  text-align: justify;
  font-family: var(---ParaFont);
  color: #111;
  font-size: 16px;
}
/* ============= Footer Address ================ */
.Footer-iconsDetails-con p i svg{
  color: var(---PrimeColor);
  font-size: 25px;
  margin-right: 20px;
}
.Footer-iconsDetails-con p span{
  color:#000;
  font-family: var(---ParaFont);
}
.Footer-MainSection {
  width: 100%;
  margin: auto;
  padding: 50px 50px;
  word-wrap: break-word;
  margin-bottom: 0;
  clear: both;
}
.Footer-MainSection-col-2 h4 {
  text-align: left;
  font-family: var(---HeadFont);
  font-size: 20px;
  font-weight: 600;
  color: var(---SecondColor);
}
.Footer-iconsDetails {
  display: flex;
  margin: 7px 0px 0px 0px;
}
.Footer-icons {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px 0px 0px;
  padding: 2px;
}
.Footer-icons svg {
  font-size: 30px;
  color: var(---PrimeColor);
}
.Footer-Details {
  text-align: left;
}
.Footer-Details a {
  text-decoration: none;
  text-align: left;
  font-family: var(---ParaFont);
  color: #111;
  font-size: 16px;
}
.Footer-Details p {
  text-align: justify;
  font-size: 16px;
  font-family: var(---ParaFont);
  color: #111;
}
.Footer-Details a {
  text-decoration: none;
  text-align: justify;
  font-size: 16px;
  font-family: var(---ParaFont);
  color: #111;
}
.Footer-Social-ICons-con {
  margin: 0px 10px 0px 0px;
}
.Footer-Social-ICons {
  height: 30px;
  width: 30px;
  border-radius: 25px;
}
.Footer-Social-ICons svg {
  font-size: 20px;
}

/* =============== Footer CopyRight ============= */
.Footer_copyRight{
background-color: #000;
}

.Footer_copyRight .copyright {
  text-align: left;
  padding: 20px 0px 20px 0px;
}
.Footer_copyRight .copyright p {
  font-family: var(---ParaFont);
  color:#fff;
  margin-bottom: 0px;
}

.Footer_copyRight .develope-by {
  text-align: right;
  padding: 20px 0px 20px 0px;
}
.Footer_copyRight .develope-by p {
  font-family: var(---ParaFont);
  text-align: right;
  color: #fff;
  margin-bottom: 0px;
}
.Footer_copyRight .develope-by p a {
  text-decoration: none;
  color: #fff;
}

@media (max-width: 990px) {
  .footer {
    padding: 20px 0px;
    height: auto;
  }
  .footer-logo img{
    object-fit: contain;
  }

  .footerUseful_link {
    margin-top: 20px;
  }
  .footerProperty_Types {
    margin-top: 20px;
  }
  .footerProperties_Available {
    margin-top: 20px;
  }
  .Footer-MainSection-col-2 {
    margin-top: 20px;
  }
  .Footer-icons{
    margin: 0px 5px 0px 0px;
  }
  .Footer_copyRight .develope-by {
    text-align: left;
  }
  .Footer_copyRight .develope-by p {
    text-align: left;
  }
  .Footer_copyRight .copyright{
    padding:0px 10px 0px 10px;
  }
  .Footer_copyRight .develope-by {
    text-align: right;
    padding:0px;
  }
}
