.navbar {
    width: 100%;
    border-bottom: 1px solid rgb(222, 222, 222);
    /* backdrop-filter: blur(8px); */
    /* -webkit-backdrop-filter: blur(8px); */
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    /* z-index: 1000; */
    padding: 10px 0px;
    background-color: #eee;
    /* background: linear-gradient(90deg, rgba(255, 255, 255, 0.3), rgba(226, 226, 226, 0.4)) */
}

.navbar-brand {
    padding: 5px 0px;
}

.Logo-Con {
    width: 100%;
    max-width: 300px;
}

.Logo-Con img {
    height: 100%;
    width: 100%;
}

/* ///////////// */

.bg-body-tertiary {
    padding: 0px !important;
}

.navbar-expand-lg .navbar-collapse {
    /* display: flex !important;
    flex-basis: auto;
    justify-content: space-between !important;
    align-items: center; */
}

.navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.navbar-expand-lg .navbar-nav .nav-link {
    margin: 0px 10px;
}

/* //////// ///////////// */

.Login-Btn {}

.Login-Btn button {
    border: none;
    padding: 6px 30px;
    font-size: 14px;
    border-radius: 50px;
    color: white;
    background: var(---Color02);
}


.navbar-expand-lg .navbar-nav .nav-link a {
    text-decoration: none;
    color: var(--PrimeColor) !important;
    text-transform: uppercase;
    font-family: var(---HeadFont);
    font-size: 14px;
    line-height: 22px;
}

.navbar-expand-lg .navbar-nav .nav-link {
    color: var(---PrimeColor) !important;
    text-transform: uppercase;
    margin: 0px;
}

.dropdown-menu a {
    color: var(---PrimeColor) !important;
}

.dropdown-menu a a {
    text-decoration: none;
    color: black !important;
}

.social-media-links-con {
    /* border: 1px solid blue; */
}

.social-media-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-media-links a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 0px;
    background-color: var(---color01);
    border-radius: 50px;
    transition: all .5s ease-in-out;
    border: 1px solid var(---color01);
}

.social-media-links a svg {
    font-size: 20px;
    color: white;
    transition: all .5s ease-in-out;
}

.social-media-links a:hover {
    background-color: white;
    border: 1px solid var(---color01);
}

.social-media-links a:hover svg {
    color: var(---color01);
}

/* login regiter btn  */

.header-login-register-con {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
}

.header-login-btn {
    /* border: 2px solid blue; */
}

.header-login-btn button {
    border: 2px solid #e17218;
    background-color: black;
    color: white;
    font-family: var(---font01);
    font-size: 13px;
    padding: 7px 15px;
    margin: 0px 5px;
}

.header-register-btn {}

.header-register-btn button {
    border: 2px solid #e17218;
    background-color: white;
    font-family: var(---font01);
    font-size: 13px;
    padding: 7px 15px;
    margin: 0px 5px;
}

/* profile  */

.header-profile-main-con {
    /* border: 1px solid red; */
    margin: 0px 5px;
}

.header-profile-main-con a{
    text-decoration: none;
}

.header-profile-con {
    border: 1px solid white;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
}

.header-profile-con svg {
    color: white;
    margin: 0px 7px 0px 0px;
}

.header-profile-con h3{
    color: white;
    font-family: var(---font01);
    font-size: 13px;
    margin: 0px;
}

.header-navbar-section-login-register-main-con{
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
}


@media(max-width:992px) {

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: auto;
        width: auto;
        display: unset;
        justify-content: center;
        text-align: right;
    }

    .Login {
        display: none !important;
    }

    .navbar-toggler {
        background-color: white !important;
    }
}

@media(max-width:430px) {
    .Logo-Con {
        width: 230px;
        display: flex;
        justify-content: center;
    }
    .banner-title h1 {
        font-size: 16px;
        padding: 0px 10px;
    }
}