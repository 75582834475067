@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

section {
  padding: 70px 0px;
}

h1 {
  font-family: var(---HeadFont);
}

h1,
h2,
h3,
h4.h5.h6 {
  font-family: var(---HeadFont);
}

p {
  font-family: var(---ParaFont);
  font-size: 16px;
  color: #808080;
  /* text-transform: capitalize; */
}


@media (max-width: 990px) {
  section {
    padding: 25px 0px;
  }
}


:root {
  ---HeadFont: "Jost", sans-serif;
  ---ParaFont: "Urbanist", sans-serif;
  ---SubHeading: "Lato", sans-serif;
  ---PrimeColor: #cb943b;
  ---SecondColor: #4e4d8e;
  ---outright: red;
  ---lease: #FF7900;
}

.nav-header {
  background-color: #fff;
  position: sticky;
  top: 0;
  width: 100%;
  margin: unset;
}

.nav-first .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: auto;
  padding: 10px;
}

.nav-logo {
  width: 85px;
}

.nav-logo img {
  width: 100%;
}

.nav-social {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-info {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-icons svg {
  font-size: 2.4rem;
}

.nav-detail {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.nav-detail strong {
  color: #000;
  text-decoration: none;
  margin-bottom: 5px;
  font-size: 1rem;
}

.nav-detail span {
  color: #808080;
  font-size: 0.85rem;
}

.nav-second {
  background-color: var(---PrimeColor);
  padding: 10px 0;
}

.nav-second {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.nav-second ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-second li {
  padding: 7px 15px;
  text-decoration: none;
  border-left: 1.5px solid #fff;
  transition: 0.3s ease-in-out;
}

.nav-second li.active,
.nav-second li:hover {
  background-color: #fff;
  border-top: 1.5px solid var(---PrimeColor);
  border-bottom: 1.5px solid var(---PrimeColor);

  border-color: var(---PrimeColor);
}

.nav-second li.active a,
.nav-second li:hover a {
  color: var(---PrimeColor);
}

.nav-second li:last-child {
  border-right: 1.5px solid #fff;
}

.nav-second li a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.drawer {
  background: transparent;
  width: 100%;
  justify-content: flex-start;
}

.drawer ul {
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.drawer ul li {
  width: 100%;
  border-top: 1px solid #808080;
  padding: 15px 10px;
}

.drawer ul li:hover,
.drawer ul li.active {
  border: unset;
  background-color: var(---PrimeColor);
}

.drawer ul li a {
  color: #000;
  display: block;
  width: 100%;
}

.drawer ul li.active a,
.drawer ul li:hover a {
  color: #fff;
}

.teamCard {
  display: flex;
  justify-content: start;
  align-items: center;
  box-shadow: 2px 3px 5px #afafaf;
  border-radius: 5px;
  padding: 10px;
}

.teamCard img {
  width: 150px;
  object-fit: cover;
  object-position: center;
  height: 30vh;
}

.teamCard .teamDetail {
  padding-left: 10px;
}

.teamCard .teamDetail h5 {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: var(---HeadFont);
}

.teamCard .teamDetail a {
  text-decoration: none;
  color: #000;
  font-family: var(---HeadFont);
}

@media(max-width:430px) {
  .teamCard .teamDetail h5 {
    font-size: 18px;
  }

  .teamCard .teamDetail a {
    text-decoration: none;
    color: #000;
    font-family: var(---HeadFont);
    font-size: 14px;
  }

}

.newNavBar{
  display: flex;
  justify-content: center;
  align-items: center;
}

.newNavBar a{
  text-decoration: none;
  color: #cb943b;
  text-transform: uppercase;
  font-size: .9rem;
}

.contactDiv{
  display: flex;
  justify-content: left;
  align-items: baseline;
} 

.contactDiv svg{
  margin-right: 5px;
  color: #cb943b;
}

.contactDiv a, .contactDiv p{
  color: #000;
  text-decoration: none;
  margin: 0;
  color: #444;
  font-size: .9rem;
}

.conatacInfo {
  max-width: 400px;
  text-align: left;
}

.new_construction{
  background-color: green !important;
}