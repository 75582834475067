/* ========= Contact Section1 ========= */
.contactBanner {
  overflow: hidden;
  width: 100% !important;
  height: 30vh;
  position: relative;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
    url("../img/newbanner/banner03.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  display: flex;
  justify-content: center;
  object-fit: cover;
}

.contact_BannerDesc h1 {
  color: var(---PrimeColor);
  font-size: 40px;
  font-family: var(---HeadFont);
  text-align: left;
  /* padding: 0px 0px; */
  text-shadow: -1px -1px 0 #000,
    /* Top-left shadow */
    1px -1px 0 #000,
    /* Top-right shadow */
    -1px 1px 0 #000,
    /* Bottom-left shadow */
    2px 2px 0 #000;
  /* Bottom-right shadow; */
}

.contact_BannerDesc p {
  color: white;
  font-family: var(---SubHeading);
  text-align: left;
}

.contact_details {
  background-color: #eee;
  padding: 20px;
  border: 1px solid grey;
  border-radius: 5px;
}

/* ========= Contact Section2 ========= */
.contact-Head {
  text-align: left;
}

.contact-Head h4 {
  font-size: 20px;
  font-weight: 700;
  padding: 10px;
}

.contact-Head h4 i {
  font-size: 40px;
  color: blue;
}

.contact-Desc a {
  text-decoration: none;
  color: black;
  font-size: 20px;
}



/* =================== Form Section =========== */
.Contact_form {
  box-shadow: 0 0 2px grey;
  padding: 25px;
}

.Contact_form h2 {
  text-align: left;
}



.Contact {
  overflow: hidden;
  width: 100% !important;
  position: relative;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Contact_list {
  color: #fff;
  text-align: left;
  padding: 20px;
}

.Contact_list h3 {
  color: #fff;
  font-size: 26px;
}

.Contact_list p {
  color: #fff;
  font-size: 16px;
}

.Contact_desc p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
}

.Contact_desc p i {
  color: #fff;
  font-size: 22px;
}

.Contact_desc p svg {
  color: #fff;
  margin-right: 10px;
  font-size: 22px;
}

.Contact_desc p a {
  color: #fff;
  text-decoration: none;
}

/* ============== Contact Section3 ============= */
.contactMap {
  width: 100%;
  /* height: 100%; */
}

.contactMap iframe {
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-height: 400px;
}

@media (max-width: 990px) {
  .contactBanner {
    height: 40vh;
  }

  .contact_BannerDesc h1 {
    font-size: 30px;
  }

  .Contact {
    margin-bottom: 20px;
  }
}


@media(max-width:430px) {
  .contact_BannerDesc h1 {
    font-size: 25px;
  }
}