.property-filter-main-con {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
}

.property-filter-data-con-main {
    /* border: 1px solid red; */
}

.property-filter-data-con {
    /* border: 1px solid green; */
}

.property-filter-inputs-main-con {
    /* border: 1px solid rgb(192, 192, 192); */
    padding: 25px 25px 0px 25px;
    border-radius: 7px;
    /* background-color: whitesmoke; */
}

.property-filter-inputs .ant-form-item {
    margin: 0px;
}

.property-filter-inputs .ant-select-single {
    height: 40px !important;
    /* border:none; */
}

.property-filter-inputs .ant-select-selector {
    border: none !important;
}

.property-filter-inputs input {
    height: 40px !important;
    border: none;
}

.property-filter-inputs button {
    width: 100%;
    height: 40px;
    font-size: 18px;
    font-family: var(---ParaFont);
    border-radius: 30px;
}

.property-filter-inputs-clear button {
    background-color: red;
    color: white;
}

.property-filter-inputs-search button {
    background-color: var(---PrimeColor);
    color: white;
}

.property-filter-inputs-search svg {
    font-size: 18px;
}

.first-filter-con-main {
    border-radius: 32px;
    box-shadow: 0 3px 12px -9px rgba(0, 0, 0, 0.4);
    border: solid 1px #909090;
    background-color: #ffffff;
    padding: 10px 20px;
}

.filter-border-con {
    border-right: 1px solid black;
}


.second-filter-con-main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.second-filter-under-con {
    margin-top: 20px;
    width: 500px;
    border-radius: 32px;
    box-shadow: 0 3px 12px -9px rgba(0, 0, 0, 0.4);
    border: solid 1px #909090;
    background-color: #ffffff;
    padding: 10px 20px;
}

.ant-select-selection-search input::placeholder {
    color: black !important;
    opacity: 0.5 !important;
}

.pagination-main-con {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Custom CSS for Input */
.ant-input::placeholder {
    color: black;
    /* Change the color to black */
}

/* Custom CSS for Select */
.property-select-color::placeholder {
    color: black !important;
    /* Change the color to black */
}

/* Custom CSS for Select placeholder */
.ant-select-selection-placeholder {
    color: black !important;
}

/* For input placeholder */
.ant-input::placeholder {
    color: black !important;
}

@media(max-width:768px) {
    .first-filter-con-main {
        border-radius: 4px;
    }

    .second-filter-under-con {
        border-radius: 4px !important;
        width: 100% !important;
    }

    .filter-border-con {
        border-right: none;
        margin-bottom: 10px;
        border-bottom: 1px solid black;
    }

    .property-filter-inputs {}

    .property-filter-inputs button {
        margin-bottom: 10px;
    }
}