/* ============= About Section1 ============= */
.awardBanner {
  overflow: hidden;
  width: 100% !important;
  height: 30vh;
  position: relative;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../img/newbanner/award\ banner.avif");
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
}

.award_BannerDesc h1 {
  color: var(---PrimeColor);
  font-size: 40px;
  font-family: var(---HeadFont);
  text-align: left;
  /* padding: 0px 0px; */
  text-shadow: -1px -1px 0 #000,
    /* Top-left shadow */
    1px -1px 0 #000,
    /* Top-right shadow */
    -1px 1px 0 #000,
    /* Bottom-left shadow */
    2px 2px 0 #000;
  /* Bottom-right shadow; */
}

.award_BannerDesc p {
  color: white;
  font-size: 16px;
  font-family: var(---SubHeading);
  text-align: left;
}


/* section 2 */

.award-section-2 {
  /* border: 1px solid blue; */
  padding: 10vh 0px;
}

.award-section-2-main-con {
  /* border: 1px solid red; */
}

.award-section-image-main-con {
  /* border: 1px solid green; */
  height: 40vh;
  margin-bottom: 30px;
}

.award-section-image-main-con .ant-image {
  height: 100%;
  width: 100%;
}

.award-section-image-main-con .ant-image-img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

@media(max-width:768px) {
  .awardBanner {
    height: 40vh;
  }
}

@media(max-width:430px) {
  .award_BannerDesc h1 {
    font-size: 25px;
  }
}